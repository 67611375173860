<!-- Componente Imagen -->

<template>
  <div class="compImagen">
    <div style="columna">
      <!-- Imagen -->
      <v-img
        :src="ctrl.value? ctrl.value : require('@/assets/img/noimg.jpg')">
      </v-img>

      <!--  -->
      <div class="centrado" v-if="disabled">
        <div>
          <v-file-input
            v-model="files"
            accept="image/jpg"
            :prepend-icon="icon"
            hide-input
            :disabled="!disabled"
            @change="previewImage">
          </v-file-input>
        </div>

        <v-btn icon @click="delete_imagen()" label="Borrar Imagen">
          <v-icon> {{ "mdi-image-remove" }} </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>



<script>

export default {
    props: {
      ctrl: { type: Object, default:null },
      record: { type:[Array, Object], default:null },
      icon: { type: String, default: "mdi-image-edit" },
      disabled: { type:Boolean, default:true }
    },


    data() {
      return {
        files:null,
      };
    },


    computed: {
      get_img() {
        if (this.ctrl.value== '-1' || this.ctrl.value=='') return require('@/assets/img/noimg.jpg');
        return this.ctrl.value;
      }
    },


    methods: {

      // elimino imagen
      delete_imagen() {
        this.$root.$alert
          .open(" ¿Seguro que desea eliminar la imagen?", "confirm")
          .then(r => {
            if (!r) return;
            this.ctrl.value= "";
            this.ctrl.comp.files="-1";
        });
      },

      // ejecuto llamada API
      async delete_continue() {
        /* let args = { tipo:'fnc', accion: 'imagendb', fn_args: { accion:'anular', tip:this.ctrl.comp.tip, tip_id:this.record.id }};
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });

        if (apiResult.sql.error) {
          this.$root.$alert.open('Error al eliminar la imagen', 'error');
          return;
        } */

        //

      },


      //
      previewImage(img) {
        // guardo imagen en el control
        this.ctrl.comp.files= this.files;

        // creo un nuevo FileReader para leer la imagen y convertirla en formato base64
        var reader = new FileReader();

        // leo la imagen como base64 image as base64 and set to imageData
        reader.onload = (e) => {
          this.ctrl.value = e.target.result;
        }

        // leo imagen como data url
        reader.readAsDataURL(img);
      }

    },

  };
</script>


